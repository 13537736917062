.thanks {
    .ino & {

        @include min-screen($tp) {
            height: calc(100vh - 220px);
        }
    }
    .usa & {

        @include min-screen($tp) {
            height: calc(100vh - 230px);
        }
    }
    .oasis & {

        @include min-screen($tp) {
            height: calc(100vh - 230px);
        }
    }
    .ssb & {

        @include min-screen($tp) {
            height: calc(100vh - 260px);
        }
    }
    .soh & {
        background-image: url('../../images/soh/transition-bg-sm.svg');
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100% 240px;
        @include min-screen($tp) {
            background-image: url('../../images/soh/transition-bg-lg.svg');
            background-size: 100%;
            margin-top: 100px;
        }
    }
    &__container {
        max-width: 1000px;
        margin: 0 auto;
        padding: 0 20px;
    }
}
.thanks-content {
    padding: 40px 0 0;
    position: relative;
    @include min-screen($tl) {
        padding: 60px 0 0;
    }
    @include min-screen($d) {
        padding: 90px 0 0;
    }
    .soh & {
        padding-top: 60px;
        @include min-screen($tl) {
            padding: 80px 0 0;
        }
        @include min-screen($d) {
            padding: 180px 0 0;
        }
    }
    &__icon {
        height: auto;
        display: block;
        margin: 0 auto 16px;

        .ino &{
            width: 60px;
            @include min-screen($d) {
                width: 64px;
            }
        }
        .usa &{
            width: 62px;
            @include min-screen($d) {
                width: 72px;
            }
        }
        .oasis &{
            width: 67px;
            @include min-screen($d) {
                width: 94px;
            }
        }
        .ssb &{
            width: 70px;
            @include min-screen($d) {
                width: 90px;
            }
        }
        .fwy &{
            width: 80px;
            @include min-screen($d) {
                width: 91px;
            }
        }
        .soh & {
            display: none;
        }
        img {
            width: 100%;
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 30px;
        @include min-screen($tl) {
            margin-bottom: 40px;
        }
        .usa & {
            margin-bottom: 40px;
            @include min-screen($tl) {
                margin-bottom: 60px;
            }
        }
        .soh & {
            margin-bottom: 20px;
            @include min-screen($tl) {
                margin-bottom: 20px;
            }
        }

        strong {
            display: block;
            font-family: $font_opensans;
            font-weight: 700;

            .ino & {
                color: $color_red-ino;
                font-size: 24px;
                @include min-screen($d) {
                    font-size: 40px;
                }
            }
            .usa & {
                color: $color_blue-usa;
                font-size: 24px;
                @include min-screen($d) {
                    font-size: 36px;
                }
            }
            .oasis & {
                color: $color_blue-oasis;
                font-size: 24px;
                @include min-screen($d) {
                    font-size: 36px;
                }
            }
            .ssb & {
                color: $color_blue-ssb;
                font-size: 24px;
                @include min-screen($d) {
                    font-size: 36px;
                }
            }
            .soh & {
                color: $color_blue-dark-soh;
                font-size: 36px;
                @include min-screen($d) {
                    font-size: 64px;
                }
            }
            .fwy & {
                color: $color_blue;
                font-size: 30px;
                font-family: $font_oswald;
                font-weight: 500;
                @include min-screen($d) {
                    font-size: 40px;
                }
            }
        }
    }

    &__subtitle {
        text-align: center;
        font-family: $font_opensans;
        
        .ino &{
            font-size: 18px;
            font-weight: 700;
            @include min-screen($d) {
                font-size: 24px;
            }
        }
                
        .usa &{
            font-size: 16px;
            font-weight: normal;
            @include min-screen($d) {
                font-size: 20px;
            }
        }
        .oasis &{
            font-size: 16px;
            font-weight: normal;
            @include min-screen($d) {
                font-size: 20px;
            }
        }
        .ssb &{
            font-size: 16px;
            font-weight: normal;
            @include min-screen($d) {
                font-size: 20px;
            }
        }
        .soh &{
            font-size: 18px;
            font-weight: normal;
        }
        .fwy &{
            font-size: 18px;
            font-weight: 600;
            @include min-screen($d) {
                font-size: 24px;
            }
        }
    }

    &__list {
        padding: 0;

        @include min-screen($tl) {
            display: flex;
            justify-content: space-between;
        }
        .ino & {
            max-width: 700px;
            margin: 25px auto 0;
        }
        .usa & {
            max-width: 800px;
            margin: 30px auto 0;
            @include min-screen($tl) {
                margin: 55px auto 0;
            }
        }
        .oasis & {
            max-width: 800px;
            margin: 30px auto 0;
            @include min-screen($tl) {
                margin: 55px auto 0;
            }
        }
        .ssb & {
            max-width: 800px;
            margin: 30px auto 0;
            @include min-screen($tl) {
                margin: 55px auto 0;
            }
        }
        .soh & {
            max-width: 800px;
            margin: 70px auto 0;
            @include min-screen($tl) {
                margin: 125px auto 0;
            }
        }
        .fwy & {
            max-width: 940px;
            margin: 30px auto 0;
            @include min-screen($tl) {
                margin: 50px auto 0;
            }
        }
    }

    &__item {
        margin-bottom: 15px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        line-height: 1.25;

        .ino & {
            border-radius: 8px;
            padding: 17px;
            font-size: 14px;
            border: solid 1px $color_black-ino;
            @include min-screen($tl) {
                padding: 16px 15px;
                flex-direction: column;
                margin-bottom: 0;
                width: calc(33.33% - 17px);
            }
        }
        .usa & {
            background-color: $color_grey2-usa;
            font-size: 16px;
            padding: 12px;
            @include min-screen($tl) {
                width: calc(33.33% - 14px);
                padding: 16px 15px ;
                min-height: 142px;
            }
        }
        .oasis & {
            background-color: $color_grey-oasis;
            font-size: 16px;
            padding: 12px;
            @include min-screen($tl) {
                width: calc(33.33% - 14px);
                padding: 16px 15px ;
                min-height: 142px;
            }
        }
        .ssb & {
            background-color: $color_grey-ssb;
            font-size: 16px;
            padding: 12px;
            line-height: 1.5;
            @include min-screen($tl) {
                width: calc(33.33% - 14px);
                padding: 16px 15px ;
                min-height: 142px;
            }
        }
        .soh & {
            background-color: $color_grey-soh;
            font-size: 16px;
            padding: 12px;
            line-height: 1.5;
            @include min-screen($tl) {
                width: calc(33.33% - 14px);
                padding: 13px 15px ;
                min-height: 130px;
            }
        }
        .fwy & {
            background-color: $color_blue-light;
            font-size: 16px;
            padding: 12px;
            line-height: 1.5;
            @include min-screen($tl) {
                width: calc(33.33% - 14px);
                padding: 13px 22px 13px 17px;
                min-height: 130px;
            }
        }

        p {
            margin: 0;
            font-family: $font_opensans;
            
        }

        strong {
            font-family: $font_opensans;
            font-weight: 700;
            padding-right: 15px;
            
            .ino & {
                color: $color_black-ino;
                font-size: 18px;
                @include min-screen($tl) {
                    font-size: 24px;
                }
            }
            .usa & {
                color: $color_blue-usa;
                font-size: 18px;
                
            }
            .oasis & {
                color: $color_blue-oasis;
                font-size: 18px;
                
            }
            .fwy & {
                color: $color_blue;
                font-size: 18px;
                
            }
        }
    }

    &__text {
        margin: 35px auto 0;
        text-align: center;
        max-width: 800px;
        font-size: 14px;
        line-height: 1.38;
        .soh & {
            font-size: 16px;
            margin: 30px auto 45px;
            @include min-screen($tl) {
                margin: 45px auto 48px;
            }
            & + .thanks-social &{
                margin-bottom: 0px;
            }
        }
        .fwy & {
            font-size: 18px;
            margin: 30px auto 45px;
            @include min-screen($tl) {
                margin: 45px auto 48px;
            }
        }
        

        p {
            margin: 0 0 20px;
            .soh & {

                @include max-screen($tl) {
                    margin: 0 0 5px;
                }
            }
            .fwy & {
                margin: 0 0 5px;
            }

            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $color_black-ino;
                .soh & {
                    color: $color_blue-soh;
                    text-decoration: none;
                }
                .fwy & {
                    color: $color_blue;
                    text-decoration: none;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        strong {
            font-family: $font_opensans;
            font-weight: 600;
        }
    }

    &__links {
        width: 133px;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-decoration: none;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: $color_white;
        margin-bottom: 16px;
        border-radius: 4px;
        margin-right: 20px;
        font-size: 15px;
        transition: box-shadow 0.2s linear;

        strong {
            font-family: $font_opensans;
            font-weight: 700;
            display: block;
            line-height: 1;

            + span {
                display: block;
                line-height: 1;
            }
        }

        &:nth-child(2n) {
            margin-right: 0;
            @include min-screen($tl) {
                margin-right: 20px;
            }
        }
        
        &:last-child {
            @include min-screen($tl) {
                margin-right: 0;
            }
        }

        &:hover {
            box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.16);
            transition: box-shadow 0.2s linear;

            .icon {
                color: $color_red;
                transition: color 0.2s linear;
            }
        }

        .icon {
            font-size: 57px;
            transition: color 0.2s linear;
        }

        &-container {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            max-width: 288px;
            margin: 0 auto;
            @include min-screen($tl) {
                max-width: 595px;
            }
        }
    }

    &__controls {
        text-align: center;
    }
    
    &-other-links {
        
        .ino & {
            padding: 30px 0;

            @include min-screen($d) {
                padding: 70px 0 90px;
            }
        }

        &__title {
            text-align: center;
            font-family: $font_opensans;
            font-weight: 700;
            margin-bottom: 36px;
            font-size: 16px;
        }

        &__text {
            text-align: center;
            font-size: 14px;
            line-height: 1.5;
            margin: 30px auto 0;
            max-width: 700px;
            @include min-screen($tl) {
                margin: 60px auto 0;
            }

            p {
                margin: 0 0 10px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            a {
                font-family: $font_opensans;
            }
        }
    }
}

.thanks-social {
    margin: 22px 0;
    @include min-screen($tl) {
        margin: 40px 0 0;
    }

    &__title {
        font-size: 18px;
        font-family: $font_opensans;
        font-weight: 600;
        text-align: center;
        @include min-screen($tp) {
            font-size: 20px;
        }
    }

    &__list {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include min-screen($tl) {
            margin-top: 24px;
        }
    }

    &__item {
        margin: 0 12px;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        color: #fff;
        border-radius: 50%;
        font-size: 16px;
        transition: all 0.2s ease;
        .ino & {
            background-color: $color_red-ino;
        }
        .usa & {
            background-color: $color_blue-usa;
        }
        .oasis & {
            background-color: $color_blue-oasis;
        }
        .ssb & {
            background-color: $color_blue-ssb;
        }
        .soh & {
            background-color: $color_blue-soh;
        }
        .fwy & {
            background-color: $color_blue;
        }
        @include min-screen($tl) {
            width: 40px;
            height: 40px;
            font-size: 20px;
        }

        &:hover {
            transition: all 0.2s ease;
            box-shadow: 0 3px 6px 0 rgba(#000, 0.16);
        }
    }
}
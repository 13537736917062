.kc-articles {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid rgba($color_black, .15);
    width: 100%;
    @include min-screen($tl) {
        margin-top: 50px;
        padding-top: 40px;
    }

    &__list {
        width: 100%;
        @include min-screen($tl) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
        }
    }

    &__item {
        margin-bottom: 20px;
        @include min-screen($tl) {
            width: calc(50% - 20px);
            margin-left: 40px;
            margin-bottom: 30px;

            &:nth-of-type(2n+1) {
                margin-left: 0;
            }

            &:nth-last-child(-n+2) {
                margin-bottom: 0;
            }
        }
        @include min-screen($d) {
            margin-bottom: 50px;
        }
    }

    &-article {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        a {
            text-decoration: none;
            display: inline-block;
        }

        &__image {
            width: 122px;
            height: 145px;
            display: block;
            overflow: hidden;
            position: relative;
            @include min-screen($tp) {
                width: 208px;
                height: 195px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                transform: scale(1);
                transition: transform 0.3s linear;
            }

            &:hover img {
                transition: transform 0.3s linear;
                transform: scale(1.1);
            }
        }

        &__content {
            padding: 9px 12px;
            width: calc(100% - 122px);
            @include min-screen($tp) {
                padding: 22px 15px 13px;
                width: calc(100% - 208px);
            }
        }

        &__date {
            display: inline-block;
            font-size: 12px;
            font-family: $font_opensans;
            margin-bottom: 5px;
        }

        &__title {
            font-family: $font_opensans;
            font-weight: 700;
            font-size: 18px;
            height: 78px;
            transition: color 0.3s linear;

            @include min-screen($d) {
                font-size: 20px;
                height: 107px;
            }

            &:hover {
                color: $color_red;
                transition: color 0.3s linear;
            }
        }
        
        &__read-more {
            margin-top: 7px;
            font-size: 14px;
            font-family: $font_opensans;
            font-weight: 700;
            color: $color_red;
        }
    }
}
.reasons {

    &-card {
        @include min-screen($d) {
            display: flex;
        }

        &__item {
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
            border-radius: 16px;
            background-color: $color_white;
            max-width: 340px;
            padding: 24px 10px 40px;
            @include min-screen($d) {
                margin: 0;
                width: calc(33.33% - 20px);
                min-height: 100%;
                margin-right: 20px;
            }

            &:last-child {
                margin-bottom: 0;
                @include min-screen($d) {                    
                    margin-right: 0;
                }
            }
        }

        &__image {
            width: 116px;
            height: auto;
            display: block;
            margin: 0 auto 16px;
            @include min-screen($d) {
                width: 132px;
            }

            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }

        &__title {
            font-size: 21px;
            font-weight: 700;
            &--blue-green {
                color: $color_blue-green;
            }
            &--green {
                color: $color_green;
            }
            &--orange {
                color: $color_orange;
            }
            
            @include min-screen($tl) {
                font-size: 22px;
            }
            .soh & {
                color: $color_black;
            }
            .usa & {
                color: $color_black;
            }
            .ino & {
                color: $color_black-ino;
            }
            .oasis & {
                color: $color_black;
            }
            .acc & {
                color: $color_green-acc;
            }
            .ssb & {
                color: $color_black;
            }
            .baja & {
                color: $color_red-baja;
            }
            
            .vernfonk & {
                color: $color_red-vf;
            }
        }

        &__text {
            line-height: 1.5;
            font-size: 16px;
            max-width: 286px;
            margin: 13px auto auto;
            font-weight: 600;
        }
    }
}
.section {
    padding: 52px 0;
    background-color: #fff;
    @include min-screen($tp) {
        padding: 40px 0;
    }


    &_skyblue {
        background-color: #F5FCFF;
    }

    &__title {
        text-align: center;
        color: $color_blue;
        font-weight: 700;
        line-height: 1.08;
        $ifz: 24px;
        $ffz: 36px;
        font-size: $ifz;
        @include min-screen($tp) {
            font-size: font-increment($ifz, $ffz, 1);
        }
        @include min-screen($tl) {
            font-size: font-increment($ifz, $ffz, 2);
        }
        @include min-screen($d) {
            font-size: $ffz;
        }
        + .section__content {
            margin-top: 40px;
        }
        .soh & {
            color: $color_blue-dark-soh;
        }
        .usa & {
            color: $color_blue-usa;
        }
        .ino & {
            color: $color_black-ino;
        }
        .oasis & {
            color: $color_blue-oasis;
        }
        .acc & {
            color: $color_blue-acc;
        }
        .ssb & {
            color: $color_blue-ssb;
        }
        .cul & {
            color: $color_blue-cul;
        }
        
        .baja & {
            color: $color_blue-baja;
        }
        
        .vernfonk & {
            color: $color_blue-vf;
        }
    }
}
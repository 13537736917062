.header {
    background-color: $color_white;
    padding: 13px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    max-width: 1400px;
    width: 100%;
    z-index: 100;
    .usa &,
    .ino &,
    .oasis & ,
    .acc & ,
    .ssb & ,
    .soh & {
        height: 60px;
        display: flex;
        @include min-screen($d) {
            height: 90px;
        }
    }

    .vernfonk & {
        padding: 18px 0;
    }
    @include min-screen($d) {
        padding: 12px 0;
        .cul &, .baja & {
            padding: 15px 0;
        }

        .vernfonk & {
            padding: 18px 0;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__brand {
        width: 128px;
        @include min-screen($tp) {
            width: 144px;
        }
        @include min-screen($tl) {
            width: 184px;
        }
        @include min-screen($d) {
            width: 214px;
        }
        .oasis &, & .acc {
            width: 128px;
            @include min-screen($d) {
                width: 174px;
            }
        }
        
        .ssb & {
            width: 132px;
            @include min-screen($d) {
                width: 160px;
            }
        }
        .usa & {
            width: 148.5px;
            @include min-screen($d) {
                width: 209px;
            }
        }
        .soh & {
            width: 90px;
            @include min-screen($d) {
                width: 163px;
            }
        }

        .cul & {
            width: 124px;
            @include min-screen($tp) {
                width: 146px;
            }
            @include min-screen($tl) {
                width: 168px;
            }
            @include min-screen($d) {
                width: 189px;
            }
        }

        .baja & {
            width: 107px;
            @include min-screen($tp) {
                width: 125px;
            }
            @include min-screen($tl) {
                width: 143px;
            }
            @include min-screen($d) {
                width: 161px;
            }
        }

        .vernfonk & {
            width: 149px;
            @include min-screen($tp) {
                width: 162px;
            }
            @include min-screen($tl) {
                width: 175px;
            }
            @include min-screen($d) {
                width: 186px;
            }
        }

        a, img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}

.phone-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    padding: 7px 12px;
    border-radius: 14px;
    background-color: $color_green;
    transition: background-color 0.2s linear;

    @include min-screen($tp) {
        background-color: transparent;
        padding: 0;
        border-radius: 0;
    }
    .soh & {
        background-color: $color_green-soh;
        @include min-screen($tp) {
            background-color: transparent;
        }
    }
    .usa & {
        background-color: $color_blue-usa;
        @include min-screen($tp) {
            background-color: transparent;
        }
    }
    .ino & {
        background-color: $color_red-ino;
        @include min-screen($tp) {
            background-color: transparent;
        }
    }
    .oasis & {
        background-color: $color_green-oasis;
        @include min-screen($tp) {
            background-color: transparent;
        }
    }
    .acc & {
        background-color: $color_green-acc;
        @include min-screen($tp) {
            background-color: transparent;
        }
    }
    .ssb & {
        background-color: $color_blue-ssb;
        @include min-screen($tp) {
            background-color: transparent;
        }
    }
    .cul & {
        background-color: $color_blue-cul;
        @include min-screen($tp) {
            background-color: transparent;
        }
    }

    .baja & {
        background-color: $color_red-baja;
        @include min-screen($tp) {
            background-color: transparent;
        }
    }

    .vernfonk & {
        background-color: $color_blue-vf;
        @include min-screen($tp) {
            background-color: transparent;
        }
    }

    &:hover {
        background-color: darken($color_green, 3%);
        transition: background-color 0.2s linear;
        @include min-screen($tp) {
            background-color: transparent;
        }

        .soh & {
            background-color: darken($color_green-soh, 3%);
            @include min-screen($tp) {
                background-color: transparent;
            }
        }

        .cul & {
            background-color: darken($color_blue-cul, 3%);
            @include min-screen($tp) {
                background-color: transparent;
            }
        }

        .baja & {
            background-color: darken($color_red-baja, 3%);
            @include min-screen($tp) {
                background-color: transparent;
            }
        }

        .vernfonk & {
            background-color: darken($color_blue-vf, 3%);
            @include min-screen($tp) {
                background-color: transparent;
            }
        }

        .usa & {
            background-color: darken($color_blue-usa, 3%);
            @include min-screen($tp) {
                background-color: transparent;
            }
        }
        .ino & {
            background-color: darken($color_red-ino, 3%);
            @include min-screen($tp) {
                background-color: transparent;
            }
        }
        .oasis & {
            background-color: darken($color_green-oasis, 3%);
            @include min-screen($tp) {
                background-color: transparent;
            }
        }
        .acc & {
            background-color: darken($color_green-acc, 3%);
            @include min-screen($tp) {
                background-color: transparent;
            }
        }
        .ssb & {
            background-color: darken($color_blue-ssb, 3%);
            @include min-screen($tp) {
                background-color: transparent;
            }
        }
    }

    &__icon {
        color: $color_green;
        font-size: 32px;
        transition: color 0.2s linear;
        display: none;
        @include min-screen($tp) {
            display: block;
        }
        @include min-screen($tl) {
            font-size: 38px;
        }

        .soh & {
            color: $color_green-soh;
        }

        .usa & {
            color: $color_blue-usa;
        }

        .ino & {
            color: $color_red-ino;
        }
        .oasis & {
            color: $color_green-oasis;
        }
        .acc & {
            color: $color_green-acc;
        }
        .ssb & {
            color: $color_blue-ssb;
        }
        
        .cul & {
            color: $color_blue-cul;
        }        

        .baja & {
            color: $color_red-baja;
        }        

        .vernfonk & {
            color: $color_blue-vf;
        }        
    }

    &:hover &__icon {
        color: darken($color_green, 3%);
        transition: color 0.2s linear;

        .soh & {
            color: darken($color_green-soh, 3%);
        }
        .usa & {
            color: darken($color_blue-usa, 3%);
        }
        .ino & {
            color: darken($color_red-ino, 3%);
        }
        .oasis & {
            color: darken($color_green-oasis, 3%);
        }
        .acc & {
            color: darken($color_green-acc, 3%);
        }
        .ssb & {
            color: darken($color_blue-ssb, 3%);
        }
        .cul & {
            color: darken($color_blue-cul, 3%);
        }

        .baja & {
            color: darken($color_red-baja, 3%);
        }

        .vernfonk & {
            color: darken($color_blue-vf, 3%);
        }
    }

    &__content {
        @include min-screen($tp) {
            margin-left: 4px;
        }
    }

    &__text {
        font-weight: 600;
        line-height: 1.14;
        color: $color_white;
        font-size: 14px;
        @include min-screen($tp) {
            font-size: 15px;
            color: $color_black;
        }
        @include min-screen($tl) {
            font-size: 16px;
            display: inline-block;
        }

        &:nth-child(1) {
            display: none;
            @include min-screen($tl) {
                display: inline-block;
            }
        }
    }

    &__number {
        display: none;
        font-size: 24px;
        font-weight: 700;
        color: $color_blue;
        margin-top: -2px;
        @include min-screen($d) {
            display: block;
        }
        .soh & {
            color: $color_blue-dark-soh;
        }

        .usa & {
            color: $color_red-usa;
        }
        .ino & {
            color: $color_black-ino;
        }
        .oasis & {
            color: $color_blue-oasis;
        }
        .acc & {
            color: $color_blue-acc;
        }
        .ssb & {
            color: $color_blue-ssb;
        }
        
        .cul & {
            color: $color_orange-cul;
        }
        
        .baja & {
            color: $color_blue-baja;
        }

        .vernfonk & {
            color: $color_red-vf;
        }
    }
}
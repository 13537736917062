.icons {
    display: none;
}
  
.container {    
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    &--small {
        max-width: 1000px;
    }

    &--large {
        max-width: 1200px;
    }
}

.icon {
    display: inline-block;
    line-height: 0.6;
    
    > svg {
        display: inline-block;
        font-size: inherit;
        width: 1em;
        height: 1em;
        overflow: hidden;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;
    }
}

.wrapper-body{
    max-width: 1400px;
    margin: 0 auto;
    @include min-screen($d) {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12)
    }
}
.main-content {
    margin-top: 60px;
    padding-bottom: 20px;
    @include min-screen($tp) {
        margin-top: 64px;
        padding-bottom: 51px;
    }
    @include min-screen($tl) {
        margin-top: 74px;
    }
    @include min-screen($d) {
        margin-top: 80px;
    }

    .cul &, .baja & {
        margin-top: 58px;
        @include min-screen($tp) {
            margin-top: 65px;
        }
        @include min-screen($tl) {
            margin-top: 70px;
        }
        @include min-screen($d) {
            margin-top: 80px;
        }
    }

    .vernfonk & {
        margin-top: 65px;
        @include min-screen($tp) {
            margin-top: 68px;
        }
        @include min-screen($tl) {
            margin-top: 74px;
        }
        @include min-screen($d) {
            margin-top: 79px;
        }
    }
    .usa &,
    .ino &,
    .oasis & ,
    .acc & ,
    .ssb & {
        margin-top: 60px;
        @include min-screen($d) {
            margin-top: 90px;
        }
    }
    .soh & {
        margin-top: 60px;
        @include min-screen($d) {
            margin-top: 90px;
        }
    }
}

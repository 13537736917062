// Fonts

@mixin font-family($font-name, $font-file, $font-weight: normal, $font-style: normal) {
    @if $isAmp {
        @font-face {
            font-family: $font-name;
            src: url('fonts/'+$font-file+'.woff2') format('woff2'), url('fonts/'+$font-file+'.woff') format('woff');
            font-weight: $font-weight;
            font-style: $font-style;
            font-display: swap;
        }
    } 
    @else {
        @font-face {
            font-family: $font-name;
            src: url('../fonts/'+$font-file+'.woff2') format('woff2'), url('../fonts/'+$font-file+'.woff') format('woff');
            font-weight: $font-weight;
            font-style: $font-style;
            font-display: swap;
        }
    }
}

@include font-family('OpenSans', 'opensans-regular', 400);
@include font-family('OpenSans', 'opensans-semibold', 600);
@include font-family('OpenSans', 'opensans-bold', 700);
@include font-family('Oswald', 'oswald-medium', 500);
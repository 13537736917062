.footer {
    background-color: $color_blue;
    color: $color_white;
    font-size: 14px;
    padding: 34px 0 25px;
    @include min-screen($d) {
        padding: 20px 0;
    }
    .soh & {
        background-color: $color_blue-soh;
    }
    .usa & {
        background-color: $color_blue-usa;
    }
    .ino & {
        background-color: $color_black-ino;
    }
    .oasis & {
        background-color: $color_blue-oasis;
    }
    .acc & {
        background-color: $color_blue-acc;
    }
    .ssb & {
        background-color: $color_blue-ssb;
    }

    .cul & {
        background: linear-gradient(180deg, #FF6E10 0%, #F25700 100%);
    }

    .baja & {
        background-color: #EFF1F5;
        color: $color_black;
    }

    .vernfonk & {
        background-color: $color_blue-vf;
    }

    &__container {
        @include min-screen($d) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    &__disclaimer {
        text-align: center;
        line-height: 1.43;
        border-bottom: 1px solid rgba(255,255,255,0.6);
        margin-bottom: 15px;
        padding-bottom: 30px;
        @include min-screen($d) {
            text-align: left;
            margin-bottom: 30px;
            width: 100%;            
            padding: 20px 0 20px;
        }
        .soh & {
            padding: 0 0 20px;
            @include min-screen($d) {
                margin-bottom: 15px;
                padding: 0 0 15px;
            }
        }

        p {
            margin: 0 0 20px;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }
        }
        span {
            font-size: 10px;
            position: relative;
            left: -3px;
            top: -6px;
        }

        strong {
            font-weight: 600;
        }
        + .footer__legal-links {
            width: auto;
            text-align: center;
            @include min-screen($d) {
                text-align: left;
            }
            .fwy & {
                text-align: left !important;
            }
           
        }
    }
    &__legal-links {
        width: 100%;
        text-align: center;
        .fwy & {
            width: auto;
            text-align: left;
        }
    }

    &__legal {
        margin: 0;
        line-height: 1.5;
    }

    &__links {
        margin-top: 20px;

        @include min-screen($d) {

        }
        .usa &,.oasis &, .acc & {
            margin-top: 10px;
        }

        a {
            display: inline-block;
            position: relative;
            .usa & {
                display: inline;
                line-height: 1.5;
            }
            
            &::before {
                content: '';
                width: 6px;
                height: 1px;
                display: inline-block;
                background-color: #fff;
                margin: 0 5px;
                transform: translateY(-3px);
                .usa &, .oasis &, .ssb & , .soh &{
                    width: 1px;
                    height: 15px;
                    transform: translateY(3px);
                    margin: 0 7px;
                }
            }
            .ssb & , .soh &{
                text-decoration: none;
            }

            &:first-child::before {
                display: none;
            }
           
        }
    }

    &__bbb {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include min-screen($d) {
            margin-top: 0;
            margin-left: 12px;
        }
    }
    &__mcafee {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include min-screen($d) {
            margin-top: 0;
            margin-left: auto;
            margin-right: 0;
        }
    }
}
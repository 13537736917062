.hero {
    @include min-screen($tp) {
        height: 460px;
        position: relative;
        .usa &,
        .ino &,
        .oasis & ,
        .acc & ,
        .ssb &,
        .soh &{
            height: 350px;
        }
    }

    &__picture {
        height: 160px;
        display: block;
        margin-bottom: -48px;
        overflow: hidden;
        @include min-screen($tp) {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            margin-bottom: 0;
            width: 100%;
        }
        .usa &,
        .ino &,
        .oasis & ,
        .acc & ,
        .ssb & ,
        .soh &{
            position: absolute;
            //top: 0;
            left: 0;
            margin-bottom: 0;
            width: 100%;
            z-index: -1;
            @include min-screen($tp) {
                height: 100%;
            }
        }
    }

    &__img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center top;
    }

    &__container {
        max-width: 1200px;
        .usa &,
        .ino &,
        .oasis & ,
        .acc & ,
        .ssb &,
        .soh & {
            padding-top: 103px;

        }

        @include min-screen($tp) {
            height: 100%;
            display: flex;
            align-items: center;
            .usa &,
            .ino &,
            .oasis & ,
            .acc & ,
            .ssb &, 
            .soh & {
                align-items: flex-start;
                padding-top: 0;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        background-color: #F5FCFF;
        border-radius: 8px;
        padding: 20px 0 46px;

        @include min-screen($tp) {
            background-color: transparent;
            padding: 0;
        }

        .soh & {
            background-color: $color_grey-soh;
            border-radius: 16px 16px 0 0;
            padding: 20px 5px 0;
            @include min-screen($tp) {
                background-color: transparent;
                padding: 87px 0 0;
            }
        }

        .usa & {
            background-color: $color_grey-usa;
            border-radius: 16px 16px 0 0;
            padding: 20px 5px 0;
            @include min-screen($tp) {
                background-color: transparent;
                padding: 87px 0 0;
            }
        }
        .ino & {
            background-color: $color_grey-ino;
            border-radius: 16px 16px 0 0;
            padding: 20px 5px 0;
            @include min-screen($tp) {
                background-color: transparent;
                padding: 87px 0 0;
            }
        }
        .oasis & {
            background-color: $color_grey-oasis;
            border-radius: 16px 16px 0 0;
            padding: 20px 5px 0;
            @include min-screen($tp) {
                background-color: transparent;
                padding: 87px 0 0;
            }
        }
        .acc & {
            background-color: $color_grey-acc;
            border-radius: 16px 16px 0 0;
            padding: 20px 5px 0;
            @include min-screen($tp) {
                background-color: transparent;
                padding: 87px 0 0;
            }
        }
        .ssb & {
            background-color: $color_grey-ssb;
            border-radius: 16px 16px 0 0;
            padding: 20px 5px 0;
            @include min-screen($tp) {
                background-color: transparent;
                padding: 87px 0 0;
            }
        }

        .cul & {
            background-color: #f4f4f4;
            @include min-screen($tp) {
                background-color: transparent;
            }
        }

        .baja &, .vernfonk & {
            background-color: #F0F1F5;
            @include min-screen($tp) {
                background-color: transparent;
            }
        }
    }

    &__title {
        text-align: center;
        padding: 0 50px;
        color: $color_blue;
        font-family: $font_oswald;
        font-weight: 500;
        $ifz: 32px;
        $ffz: 60px;
        font-size: $ifz;
        line-height: 1.35;
        @include min-screen($tp) {
            font-size: font-increment($ifz, $ffz, 1);
            text-align: left;
            padding: 0;
        }
        @include min-screen($tl) {
            font-size: font-increment($ifz, $ffz, 2);
        }
        @include min-screen($d) {
            font-size: $ffz;
        }

        .soh & {
            color: $color_blue-dark-soh;
            font-family: $font_opensans;
            font-weight: 700;
            padding: 0;
            $ifz: 26px;
            $ffz: 36px;
            font-size: $ifz;
            max-width: 560px;
            @include min-screen($tp) {
                font-size: font-increment($ifz, $ffz, 1);
            }
            @include min-screen($tl) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include min-screen($d) {
                font-size: $ffz;
            }
        }
        
        .cul & {
            font-family: $font_opensans;
            font-weight: 700;
            color: $color_orange-cul;
            padding: 0;
            $ifz: 30px;
            $ffz: 52px;
            font-size: $ifz;
            @include min-screen($tp) {
                font-size: font-increment($ifz, $ffz, 1);
            }
            @include min-screen($tl) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include min-screen($d) {
                font-size: $ffz;
            }
        }

        .baja & {
            font-family: $font_opensans;
            font-weight: 700;
            color: $color_blue-baja;
            padding: 0;
            $ifz: 30px;
            $ffz: 52px;
            font-size: $ifz;
            @include min-screen($tp) {
                font-size: font-increment($ifz, $ffz, 1);
            }
            @include min-screen($tl) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include min-screen($d) {
                font-size: $ffz;
            }
        }

        .vernfonk & {
            font-family: $font_opensans;
            font-weight: 700;
            color: $color_blue-vf;
            padding: 0;
            $ifz: 30px;
            $ffz: 52px;
            font-size: $ifz;
            @include min-screen($tp) {
                font-size: font-increment($ifz, $ffz, 1);
            }
            @include min-screen($tl) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include min-screen($d) {
                font-size: $ffz;
            }
        }
        .usa & {
            color: $color_red-usa;
            font-family: $font_opensans;
            font-weight: 700;
            padding: 0;
            $ifz: 30px;
            $ffz: 52px;
            font-size: $ifz;
            
            @include min-screen($tp) {
                font-size: font-increment($ifz, $ffz, 1);
            }
            @include min-screen($tl) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include min-screen($d) {
                font-size: $ffz;
            }
           
        }
        .ino & {
            color: $color_black-ino;
            font-family: $font_opensans;
            font-weight: 700;
            padding: 0 2px;
            $ifz: 22px;
            $ffz: 38px;
            font-size: $ifz;

            @include min-screen($tp) {
                font-size: font-increment($ifz, $ffz, 1);
                padding: 0;
            }
            @include min-screen($tl) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include min-screen($d) {
                font-size: $ffz;
            }
        }
        .oasis & {
            color: $color_blue-oasis;
            font-family: $font_opensans;
            font-weight: 700;
            padding: 0;
            $ifz: 30px;
            $ffz: 52px;
            font-size: $ifz;
            
            @include min-screen($tp) {
                font-size: font-increment($ifz, $ffz, 1);
            }
            @include min-screen($tl) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include min-screen($d) {
                font-size: $ffz;
            }
            
        }
        .acc & {
            color: $color_blue-acc;
            font-family: $font_opensans;
            font-weight: 700;
            padding: 0;
            $ifz: 30px;
            $ffz: 52px;
            font-size: $ifz;
            
            @include min-screen($tp) {
                font-size: font-increment($ifz, $ffz, 1);
            }
            @include min-screen($tl) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include min-screen($d) {
                font-size: $ffz;
            }
            
        }
        .ssb & {
            color: $color_yellow-ssb;
            font-family: $font_opensans;
            font-weight: 700;
            padding: 0;
            $ifz: 21px;
            $ffz: 38px;
            font-size: $ifz;
           
            @include min-screen($tp) {
                font-size: font-increment($ifz, $ffz, 1);
            }
            @include min-screen($tl) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include min-screen($d) {
                font-size: $ffz;
            }
            
        }

        strong {
            color: $color_green;
            font-weight: 500;

            .soh & {
                color: $color_green-soh;
                font-weight: 700;
            }

            .cul & {
                color: $color_blue-cul;
                font-weight: 700;
            }
            .baja & {
                color: $color_red-baja;
                font-weight: 700;
            }
            .vernfonk & {
                color: $color_red-vf;
                font-weight: 700;
            }
            .usa & {
                color: $color_blue-usa;
                font-weight: 700;

            }
            .ino & {
                color: $color_red-ino;
                font-weight: 700;
            }
            .oasis & {
                color: $color_green-oasis;
                font-weight: 700;
            }
            .acc & {
                color: $color_green-acc;
                font-weight: 700;
            }
            .ssb & {
                color: $color_yellow-ssb;
                font-weight: 700;
            }
        }

        a {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 700;
            $ifz: 14px;
            $ffz: 18px;
            font-size: $ifz;
            vertical-align: top;
            text-decoration: none;
            @include min-screen($tp) {
                font-size: font-increment($ifz, $ffz, 1);
            }
            @include min-screen($tl) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include min-screen($d) {
                font-size: $ffz;
            }
            .usa &,
            .ino &,
            .oasis & ,
            .acc & ,
            .ssb & ,
            .soh & {
                $ifz: 14px;
                $ffz: 25px;
                font-size: $ifz;
                vertical-align: top;
                text-decoration: none;
                @include min-screen($tp) {
                    font-size: font-increment($ifz, $ffz, 1);
                }
                @include min-screen($tl) {
                    font-size: font-increment($ifz, $ffz, 2);
                }
                @include min-screen($d) {
                    font-size: $ffz;
                }
            }
        }

        span {
            display: block;
            font-family: $font_opensans;
            $ifz: 16px;
            $ffz: 24px;
            font-size: $ifz;
            font-weight: 600;
            color: $color_black;
            @include min-screen($tp) {
                font-size: font-increment($ifz, $ffz, 1);
            }
            @include min-screen($tl) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include min-screen($d) {
                font-size: $ffz;
            }
            .usa &,
            .ino &,
            .oasis &{
                $ifz: 18px;
                $ffz: 24px;
                font-size: $ifz;
                font-weight: 600;
                color: $color_black;
                @include min-screen($tp) {
                    font-size: font-increment($ifz, $ffz, 1);
                }
                @include min-screen($tl) {
                    font-size: font-increment($ifz, $ffz, 2);
                }
                @include min-screen($d) {
                    font-size: $ffz;
                }
               
            }
            .oasis &, .acc & {
                @include max-screen($ml) {
                    max-width: 238px;
                    margin: auto;
                }
            }
            .soh & {
                color: $color_green-soh;
                $ifz: 26px;
                $ffz: 32px;
                font-size: $ifz;
                font-weight: 700;
                @include min-screen($tp) {
                    font-size: font-increment($ifz, $ffz, 1);
                }
                @include min-screen($tl) {
                    font-size: font-increment($ifz, $ffz, 2);
                }
                @include min-screen($d) {
                    font-size: $ffz;
                }
            }
            .ssb & {
                color: $color_blue-ssb;
                $ifz: 18px;
                $ffz: 32px;
                font-size: $ifz;
                font-weight: 700;
                max-width: 550px;
                margin: auto;
                @include min-screen($tp) {
                    font-size: font-increment($ifz, $ffz, 1);
                }
                @include min-screen($tl) {
                    font-size: font-increment($ifz, $ffz, 2);
                }
                @include min-screen($d) {
                    font-size: $ffz;
                }
                
            }
            
        }
    }

    &-form {
        margin-top: 26px;
        padding: 0 50px;
        max-width: 408px;
        margin-left: auto;
        margin-right: auto;
        @include min-screen($tp) {
            padding: 0;
            margin-top: 22px;
            max-width: none;
        }

        &__form {
            @include min-screen($tp) {
                display: flex;
                align-items: flex-start;
            }
        }

        &__item {
            @include min-screen($tp) {
                width: 200px;
            }
        }

        &__input {
            display: block;
            width: 100%;
            height: 50px;
            background: #FFFFFF;
            border: 1px solid #8AB2CB;
            box-shadow: 0px 0px 6px rgba(0, 71, 187, 0.8);
            border-radius: 8px;
            font-size: 20px;
            font-weight: 600;
            padding: 0 20px;
            text-align: center;
            color: $color_black;
            font-family: $font_opensans;
            transition: all 0.2s linear;
            @include placeholder(#9DA4A7);
            @include min-screen($tp) {
                text-align: left;
            }

            .form__item_filled & {
                padding-top: 14px;
                background-position: 10px 20px;
                transition: all 0.2s linear;
            }

            &.form__valid {
                border: 2px solid $color_green;
            }

            &.form__error {
                border: 2px solid red;
            }
        }

        label.form__error {
            background-color: $color_red;
            color: #fff;
            font-size: 12px;
            padding: 6px 9px;
            display: inline-block;
            margin-top: 7px;
            border-radius: 6px;
            position: relative;
            margin-left: 10px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            @include min-screen($tl) {
                font-size: 15px;
            }

            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                top: -5px;
                left: 20px;
                width: 1px;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 5px solid $color_red;
            }
        }

        &__controls {
            margin-top: 8px;
            @include min-screen($tp) {
                margin-top: 0;
                margin-left: 8px;
            }
        }

        &__submit {
            font-family: $font_opensans;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color_orange;
            height: 45px;
            color: $color_white;
            text-align: center;
            font-weight: 700;
            width: 100%;
            font-size: 20px;
            cursor: pointer;
            padding: 0 12px;
            box-shadow: none;
            transition: box-shadow 0.2s linear;
            border-radius: 30px;            
            @include min-screen($tp) {
                height: 50px;
                padding: 0 20px;
            }

            &:hover {
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18);
                transition: box-shadow 0.2s linear;
            }

            .soh & {
                background-color: $color_green-soh;
            }
            .acc & {
                background-color: #cb4b00;
            }
            .cul & {
                background-color: $color_blue-cul;
            }

            .baja & {
                background-color: $color_red-baja;
            }

            .vernfonk & {
                background-color: $color_red-vf;
            }

            img {
                display: inline-block;
                width: 28px;
                height: auto;
                vertical-align: middle;
                display: none;
                margin-left: 4px;

                .form_loading & {
                    display: inline-block;
                }
            }
        }

    }

    &-review {
        margin-top: 36px;
        @include min-screen($tp) {
            width: 400px;
            transform: translateX(-85px);
        }
    }
}
///* ========================================================================
//   #MEDIA BLOCK
//   ======================================================================== */

.media-block {
    @include max-screen($d) {
        display: flex;
        flex-direction: column-reverse;

    }

    @include min-screen($d) {
        position: relative;
        min-height: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        //padding: 0 20px;
    }    

    &--left  {
        @include min-screen($d) {
            flex-direction: row-reverse;
        }
    }

    &--right  {
        @include min-screen($d) {
            flex-direction: row
        }
    }

    &__title {
        color: $color_blue;
        font-weight: 700;
        margin-bottom: 16px;
        $ifz: 24px;
        $ffz: 36px;
        font-size: $ifz;
        line-height: 1.27;

        @include min-screen($tp) {
            font-size: font-increment($ifz, $ffz, 1);
        }
        @include min-screen($tl) {
            font-size: font-increment($ifz, $ffz, 2);
        }
        @include min-screen($d) {
            font-size: $ffz;
            margin-bottom: 24px;
            line-height: 1.17;
        }
        .soh & {
            color: $color_blue-dark-soh;
        }
        .usa & {
            color: $color_blue-usa;
        }
        .ino & {
            color: $color_black-ino;

        }
        .oasis & {
            color: $color_blue-oasis;
        }
        .acc & {
            color: $color_blue-acc;
        }
        .ssb & {
            color: $color_blue-ssb;
        }

        .cul & {
            color: $color_blue-cul;
        }
        
        .baja & {
            color: $color_blue-baja;
        }
        
        .vernfonk & {
            color: $color_blue-vf;
        }
    }

    &__picture {
        width: 240px;
        height: 240px;
        margin: 0 auto 24px ;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        @include min-screen($d) {
            width: 320px;
            height: 320px;
            margin: 0;
        }

        &--circle {
            border-radius: 50%;
            overflow: hidden;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    &__content{
        font-size: 16px;
        @include min-screen($d) {
            width: calc(100% - 360px);
        }
        .media-block--left & {
            @include min-screen($d) {
                margin-left: 40px;
            }
        }

        .media-block--right & {
            @include min-screen($d) {
                margin-right: 40px;
            }
        }
        p {
            margin: 0 0 15px;
            line-height: 1.5;

            &:last-child {
                margin-bottom: 0;
            }
            span {
                font-size: 9px;
                transform: translate(0px, -7px);
                display: inline-block;
            }
        }
    }

    .section__content {
        @include min-screen($d) {
            text-align: left;
        }
    }
}
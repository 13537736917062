// General
* {
    -webkit-font-smoothing: antialiased;
    /* No bold in Mac. */
    box-sizing: border-box;

    .is-keyboard-pressed &:focus {
        outline: 2px solid orange;
    }

    &:focus {
        outline: none;
    }
    
    &:active {
        outline: none;
    }
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: $font_opensans;
    color: $color_black;
}
  
h1,h2,h3,h4,h5,h6 {
    margin: 0;
}
  
a {
    color: inherit;
}

input {
    border: none;
}
ul {
    list-style: none;
    padding: 0;
}
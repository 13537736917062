.quote {
    padding: 0 20px;
    
    @include min-screen($tp) {
        margin-top: -50px;
    }
    
    &__container {
        max-width: 860px;
        padding: 25px 18px 30px;
        border-radius: 0 0 16px 16px;
        background-color: #F5FCFF;
        @include min-screen($tp) {
            padding: 35px 54px 30px;
            border-radius: 16px;
        }

        .soh & {
            background-color: $color_grey-soh;
        }
        .usa & {
            background-color: $color_grey-usa;
        }
        .ino & {
            background-color: $color_grey-ino;
        }
        .oasis & {
            background-color: $color_grey-oasis;
        }
        .acc & {
            background-color: $color_grey-acc;
        }
        .ssb & {
            background-color: $color_grey-ssb;
        }
    }

    &__title {
        font-size: 14px;
        line-height: 1.24;
        margin: 0 auto;
        color: $color_black;
        font-weight: 400;
        @include min-screen($tp) {
            font-size: 16px;
            max-width: none;
            text-align: center;
            font-weight: 700;
        }
    }

    &__form {
        margin-top: 29px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        @include min-screen($tl) {
            margin-top: 38px;
        }
    }

    &__item {
        position: relative;
        margin-bottom: 8px;

        &_m50 {
            width: calc(50% - 7px);
        }

        &_m100 {
            width: 100%;
        }

        &_d33 {
            @include min-screen($tl) {
                width: calc(33.33% - 10px);
            }
        }
    }

    &__label {
        font-size: 12px;
        display: inline-block;
        padding-left: 8px;
        margin-bottom: 5px;
    }

    &__input, &__select {
        border: 1px solid #BDBDBD;
        background-color: #fff;
        padding: 0 10px 0 18px;
        border-radius: 10px;
        font-family: $font_opensans;
        font-size: 18px;
        color: #000;
        display: block;
        width: 100%;
        height: 50px;
        appearance: none;
        @include placeholder(#000);
        @include min-screen($tl) {
            padding: 0 11px;
        }

        &.form__valid {
            border: 1px solid $color_green;
        }

        &.form__error {
            border: 1px solid $color_red;
        }
    }

    &__select {
        padding-right: 6px;
    }

    label.form__error {
        background-color: $color_red;
        color: #fff;
        font-size: 12px;
        padding: 6px 9px;
        display: inline-block;
        margin-top: 7px;
        border-radius: 6px;
        position: relative;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        @include min-screen($tl) {
            font-size: 13px;
        }

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -5px;
            left: 20px;
            width: 1px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 5px solid $color_red;
        }
    }

    &__text {
        font-size: 11px;
        font-family: $font_opensans;
        line-height: 1.5;
        color: #4f4f4f;
        margin-top: 10px;
        @include min-screen($tl) {
            margin-top: 8px;
        }

        p {
            margin: 0 0 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        strong {
            font-weight: normal;
        }

        a {
            font-weight: 600;
            color: $color_black;
            &:hover {
                transition: color 0.2s linear;
                opacity: .8;
            }
            .soh & {
                color: $color_blue-soh;
            }
            .usa & {
                color: $color_blue-usa;
            }
            .oasis & {
                color: $color_blue-oasis;
            }
            .acc & {
                color: $color_blue-acc;
            }
            .ssb & {
                color: $color_blue-ssb;
            }
            
        }
    }

    &__controls {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 8px;
        justify-content: center;
        @include min-screen($tl) {
            
        }
    }

    &__submit {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color_orange;
        height: 50px;
        color: $color_white;
        text-align: center;
        font-weight: 700;
        padding: 0 30px;
        font-size: 22px;
        cursor: pointer;
        box-shadow: none;
        transition: box-shadow 0.2s linear;
        border-radius: 30px;
        @include min-screen($tl) {
            height: 50px;
        }

        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18);
            transition: box-shadow 0.2s linear;
        }
        .soh & {
            background-color: $color_green-soh;
        }
        .usa & {
            background-color: $color_red-usa;
        }
        .ino & {
            background-color: $color_red-ino;
        }
        .oasis & {
            background-color: $color_green-oasis;
        }
        .acc & {
            background-color: $color_green-acc;
        }
        .ssb & {
            background-color: $color_blue-ssb;
        }

        img {
            display: inline-block;
            width: 28px;
            height: auto;
            vertical-align: middle;
            display: none;
            margin-left: 4px;

            .form_loading & {
                display: inline-block;
            }
        }
    }

    &__hashtag {
        margin: 65px auto 0;
        text-align: center;
        @include min-screen($tl) {
            margin-top: 40px;
        }

        p {
            margin: 0;
            font-weight: 700;
            color: $color_blue;
            font-size: 24px;
            @include min-screen($tl) {
                font-size: 22px;
            }
        }
    }

    + .section {
        margin-top: 15px;
        @include min-screen($d) {
            margin-top: 40px;
        }
    }
}